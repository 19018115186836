.narrow {
  width: 900px;
  margin: 0 auto;
}

.page-padding {
  padding: 4rem;
}

.welcome.page > .narrow > img {
  margin: 0 auto;
  display: block;
  width: 200px;
}

.welcome.page > .narrow > ul {
  width: 80%;
  justify-content: space-between;
  margin: 4rem auto;
  border-bottom: none;
}

.welcome.page > .narrow > ul > li {
  background-color: inherit;
  margin: auto;
}

.welcome.page > .narrow > ul > li > a {
  font-size: 14px;
  min-height: 32px;
  border-bottom-color: rgb(98, 100, 167);
}

.center {
  text-align: center;
}

.sections > * {
  margin: 4rem auto;
}

.tabList {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 30px 20px;
  row-gap: 30px;
}

pre,
div.error {
  background-color: #e5e5e5;
  padding: 1rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin: 1rem 0;
  max-height: 200px;
  overflow-x: scroll;
  overflow-y: scroll;
  max-width: 732px;
}

pre.fixed,
div.error.fixed {
  height: 200px;
}

code {
  background-color: #e5e5e5;
  display: inline-block;
  padding: 0px 6px;
  border-radius: 3px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

code::-webkit-scrollbar,
pre::-webkit-scrollbar {
  display: none;
}

.contrast pre,
.contrast code,
.contrast div.error {
  background-color: #000000;
  border-color: #ffffff;
  border-width: thin;
  border-style: solid;
}

.dark pre,
.dark code,
.dark div.error {
  background-color: #1b1b1b;
}

.error {
  color: red;
}


.versational-logo {
  width: 150px;
}

.teams-logo {
  width: 140px;
}

.container{width: calc(100% - 450px); height:15px; background:#F8B200; margin:100px auto;}
.arrow-right {
	width: 0; 
	height: 0; 
	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
	border-left: 15px solid #F8B200;
    float:right;
    margin-top:-7px;
    margin-right:-15px;
}

.arrow-left {
	width: 0; 
	height: 0; 
	border-top:15px solid transparent;
	border-bottom: 15px solid transparent; 
	float:left;
	border-right:15px solid #F8B200;
    margin-top:-7px;
    margin-left:-15px;
}

.success-wrapper {
  display: flex;
  align-items: center;
}

.versational-banner {
  width: 70% !important;
  margin-top: -30px !important;
}

.auth-page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
}

button {
  background-color: #F8B200 !important;
  color: #fff !important;
}

button:hover {
  background-color: #ffc929 !important;
}