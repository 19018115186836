* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

body .narrow {
  max-width: 900px;
  width: 100%;
}

.help-page {
  padding: 20px;
}

.help-page img {
  width: 100%;
}

.help-page h2 {
  margin-top: 50px;
}

.help-page h1, .help-page h2 {
  line-height: 1.15;
}